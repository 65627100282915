<template xmlns="http://www.w3.org/1999/html">
  <div>
    <v-row class="justify-center">
      <ca-page-header :color="null">
        <template #icon>
          <v-img :src="medalImg" />
        </template>

        <template #title>
          <div class="app-page-video-header">
              <i18n path="pages.pageVideo.premium_title">
                <template #premium_sub_title>
                  <span class="amber--text text--darken-2">{{ t("premium_sub_title") }}</span>
                </template>

                <template #br>
                  <br />
                </template>
              </i18n>
            <v-img :src="betaImg" />
          </div>
        </template>
        <span v-html="t('premium_video_sub_title')"></span>
      </ca-page-header>
    </v-row>

    <v-row class="justify-center">
      <v-col class="col-xs-12" :md="5" :cols="10">
        <v-card>
          <v-card-text>
            <i18n path="pages.pageVideo.player_text">
              <template #embed_code>
                <strong>{{ t("embed_code")}}</strong>
              </template>
              <template #br>
                <br />
              </template>
            </i18n>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col :md="5" :cols="10">
        <v-img :src="VideoPageExample" />
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col :sm="12" :md="10" :lg="10">
        <div class="text-center">
          <div class="mb-2 mt-2 text-24">{{ t("tools") }}</div>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col class="col-xs-12" :md="10" :cols="10">
        <v-card>
          <v-card-title>{{ t("convert_video") }}</v-card-title>
          <v-card-subtitle v-html="t('convert_video_text_1')"></v-card-subtitle>
          <v-card-text>
            <div class="d-flex">
              <strong>
                {{ t("manual_level") }}
              </strong>
            </div>

            <i18n path="pages.pageVideo.manual_level_text">
              <template #manage_file_link>
                <a @click="loginTo('file_manager')"> {{ t("manager_files") }} </a>
              </template>
              <template #br>
                <br />
              </template>
              <template #iconPlus>
                <v-icon>mdi-plus</v-icon>
              </template>
              <template #iconVideoPlus>
                <v-icon>mdi-video-plus-outline</v-icon>
              </template>
            </i18n>

          </v-card-text>

          <v-card-text>
            <div class="d-flex">
              <strong>
                {{ t("auto_level") }}
              </strong>
            </div>

            <i18n path="pages.pageVideo.auto_level_text">
              <template #manage_file_link>
                <a @click="loginTo('file_manager')"> {{ t("manager_files") }} </a>
              </template>
              <template #br>
                <br />
              </template>
              <template #iconSettings>
                <v-icon>mdi-cog-outline</v-icon>
              </template>
            </i18n>
          </v-card-text>

          <v-card-title>{{ t("oauth_title") }}</v-card-title>
          <v-card-text>
            <i18n path="pages.pageVideo.oauth_text">
              <template #br>
                <br />
              </template>
              <template #support_link>
                <a :href="$t('app.feedback_link')" target="_blank"> {{ t("support_link") }} </a>
              </template>
            </i18n>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col class="col-xs-12" :md="10" :cols="10">
        <v-card>
          <v-card-title>{{ t("public_video_pay") }}</v-card-title>
          <v-card-text>
            <div class="d-flex">
              <strong>
                {{ t("embed_version") }}
              </strong>
            </div>

            <i18n path="pages.pageVideo.embed_version_text">
              <template #manage_file_link>
                <a @click="loginTo('file_manager')"> {{ t("manager_files") }} </a>
              </template>
              <template #br>
                <br />
              </template>
              <template #embed_code>
                <strong>{{ t("embed_code")}}</strong>
              </template>
            </i18n>
          </v-card-text>

          <v-card-text>
            <div class="d-flex">
              <strong>
                {{ t("earnings") }}
              </strong>
            </div>
            <div class="d-flex mt-2" v-html="t('earnings_text_1')"></div>
            <div class="d-flex mt-2" v-html="t('earnings_text_2')"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col class="col-xs-12" :md="10" :cols="12">
        <div class="text-center">
          <div class="mb-2 mt-2 text-24">{{ t("example_video") }}</div>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col class="col-xs-12" :md="10" :cols="10">
        <div class="text-center">
          <iframe width="640" height="480" src="https://tbvid.net/embed/nmjgqmzikujz" frameborder="0" allowfullscreen></iframe>
        </div>
          <div class="text-center" v-html="t('video_text')"></div>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col class="col-xs-12" :md="10" :cols="10">
        <div class="text-center">
          <div class="mb-2 mt-2 text-24">{{ t("addition_info") }}</div>
        </div>
        <div>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="faqItem in faqItemsByVideoCategory(faqItems)"
            >
              <v-expansion-panel-header>
                <div>
                  <span class="d-block mb-1 text-body-1 text--darken-1">
                    <strong>{{ faqItem.question }}</strong>
                  </span>
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div class="text-body-1" v-html="replaceTemplate(faqItem.answer)"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>

  </div>

</template>

<script>
import CaPageHeader from "../../components/PageHeader";
import MedalImg from "@/assets/images/svg/video-page.svg";
import BetaImg from "@/assets/images/svg/beta.svg";
import VideoPageExample from "@/assets/images/svg/video-page-example.svg";
import { mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: this.$t("titles.video_page")
    };
  },
  data() {
    return {
      faqItems: [],
    };
  },
  components: { CaPageHeader },

  computed: {
    ...mapGetters("app", ["appDomain", "redirectorDomain", "cabinetDomain", "filemanagerDomain"]),
    medalImg() {
      return MedalImg;
    },
    betaImg() {
      return BetaImg;
    },
    VideoPageExample() {
      return VideoPageExample;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    t(key, params) {
      return this.$t("pages.pageVideo." + key, params);
    },
    loginTo(app) {
      this.$store.dispatch("auth/loginToApp", { app });
    },
    loadData() {
      this.axios.get("/faq").then(response => {
        this.faqItems = response.data.items;
      });
    },

    /**
     * Заменяет urls в тексте
     */
    replaceTemplate(answer) {
      return answer
              .replaceAll("{fd1_domain}", this.redirectorDomain("fd1"))
              .replaceAll("{fd2_domain}", this.redirectorDomain("fd2"))
              .replaceAll("{ptr_domain}", this.appDomain("ptr"))
              .replaceAll("{ptr_cabinet_domain}", this.cabinetDomain)
              .replaceAll("{ptr_filemanager_domain}", this.filemanagerDomain);
    },

    /**
     * возвращает вопросы по категории
     */
    faqItemsByVideoCategory() {
      return this.faqItems.filter(x => x.category_id === 8);
    },
  }
};
</script>


<style lang="scss" scoped>
.app-page-video-header {
  display: flex;
  justify-content: center;

  .v-image {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    width: 24px;
    height: 24px;
  }
}
</style>