<template>
  <div class="text-center mb-6">
    <v-avatar :color="colors" tile :size="size" rounded>
      <slot name="icon">
        <v-icon :color="color">
          {{ icon }}
        </v-icon>
      </slot>
    </v-avatar>

    <h3>
      <slot name="title">
        {{ title }}
      </slot>
    </h3>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CaPageHeader",
  props: {
    title: {
      type: String,
      default: "Page header"
    },
    icon: {
      type: String,
      default: "mdi-account-cog"
    },
    color: {
      type: String,
      default: "primary"
    },
    size: {
      type: Number,
      default: 44
    }
  },
  computed: {
    colors() {
      return "lighten-4 rounded " + this.color;
    }
  }
};
</script>
